import React from "react";
// import sections
import Hero from "../components/sections/Hero";
// import FeaturesTiles from "../components/sections/FeaturesTiles";
import Partners from "../components/sections/Partners";
import { ScrollToTop } from "../components/elements/ScrollToTop";
import Projects from "../components/sections/Projects";
import About from "../components/sections/About";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import Contact from "../components/Contact/Contact";
import Banner from "../components/Banner";
const Home = () => {
  AOS.init({
    once: false,
  });
  useTranslation([]);
  return (
    <>
      <ScrollToTop />
      <Hero className="illustration-section-01" />
      {/* <FeaturesTiles /> */}
      <About />
      <Banner />
      <Projects />
      <Partners />
      <Contact />
    </>
  );
};

export default Home;
