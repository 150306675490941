import React, { Component } from "react";
import { t } from "i18next";
import "../../assets/scss/core/sections/_contact.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from "react-google-recaptcha";
import { IoMdArrowDropdown } from "react-icons/io";
import { AiOutlineWhatsApp } from "react-icons/ai";
import emailjs from "@emailjs/browser";
import Joi from "joi-browser";
import Input from "./Input";
import TextAreaField from "./TextAreaField";
import "./alert.scss";
export default class Contact extends Component {
  state = {
    isVerified: false,
    show: false,
    inputs: {
      name: "",
      email: "",
      message: "",
    },
    errors: {},
  };
  schema = {
    name: Joi.string().required().label("name"),
    email: Joi.string().required().label("email"),
    message: Joi.string().required().label("message"),
  };

  validate = () => {
    const { error } = Joi.validate(this.state.inputs, this.schema);

    if (!error) return null;

    const errors = {};

    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };
  handleOnChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const inputs = { ...this.state.inputs };
    inputs[input.name] = input.value;
    // this.setState({})
    this.setState({ inputs, errors });
  };
  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });

    console.log("Submitted");
  };
  handleChange = (token) => {
    this.setState({ isVerified: true, recaptchaToken: token });
  };
  sendEmail = (e) => {
    e.preventDefault();

    if (!this.state.isVerified) {
      alert("Please complete the reCAPTCHA verification");
      return;
    }

    const formData = new FormData(e.target);
    formData.append('g-recaptcha-response', this.state.recaptchaToken);

    emailjs
      .sendForm(
        "service_79vu493",
        "template_7czjsr4",
        e.target,
        "noTujS5CU4tTos-kb"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  render() {
    const { inputs, errors } = this.state;
    return (
      <section className="page-section" id="contact">
        <h1
          className="text-dark center-content"
          style={{ margin: "0px", fontSize: "44px" }}
        >
          {t("Contactus")}
        </h1>
        <div className="container">
          <div className="row pt-5 pl-3">
            <div className="col-md-6 topic">
              <h3 className="text-dark">{t("E-mailus")}</h3>
              <form onSubmit={this.sendEmail}>
                <div className="row-md-6">
                  <div>
                    <label>Name</label>
                    <Input
                      name="name"
                      value={inputs.name}
                      onChange={this.handleOnChange}
                      error={errors.name}
                    />
                  </div>
                  <div>
                    <label>E-Mail *</label>
                    <Input
                      name="email"
                      value={inputs.email}
                      onChange={this.handleOnChange}
                      error={errors.email}
                    />
                  </div>
                  <div>
                    <label>{t("Message")}</label>
                    <TextAreaField
                      name="message"
                      value={inputs.message}
                      onChange={this.handleOnChange}
                      error={errors.message}
                    />
                  </div>
                  <div className="row">
                    <div className="pt-4 col-sm-4 text-center">
                    <ReCAPTCHA
                      className="recaptcha"
                      sitekey="6Le88kojAAAAAOV2Nb56h_AQV7jLKeXKonKJyMtq"
                      onChange={this.handleChange}
                    />
                      <button
                        disabled={this.validate() || !this.state.isVerified}
                        type="submit"
                        className="btnD2"
                      >
                        {t("Send")}
                      </button>
                    </div>
                    <div className="col-sm-4"></div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-6 rightcontainer">
              <div className="whatsappcontainer">
                <a href="https://wa.me/41791799118">
                  <p style={{ color: "white" }}>
                    <AiOutlineWhatsApp
                      color="white"
                      style={{ display: "inline", marginRight: "10px" }}
                    />
                    {t("SendusamessageonWhatsApp")}
                  </p>
                </a>
              </div>

              <h4 className="text-dark">edmmo.swiss AG</h4>
              <p>
                <a
                  className="text-dark"
                  href="https://goo.gl/maps/HAXyJh9wqzNCbYDPA"
                >
                  Obere Zollgasse 29B, Ostermundigen, Schweiz
                </a>
              </p>
              <a className="text-dark" href="tel:0315371922">
                +41 31 537 19 22
              </a>
              <h4 className="pt-1 text-dark">{t("Ouropeninghours")}</h4>
              <div>
                <p>
                  {t("Workinghours")}
                  <IoMdArrowDropdown
                    style={{ display: "inline", cursor: "pointer" }}
                    onClick={() => this.setState({ show: !this.state.show })}
                  />
                </p>
                {this.state.show ? (
                  <tbody>
                    <tr>
                      <td>{t("Mon")}</td>
                      <td>09-00-17:30</td>
                    </tr>
                    <tr>
                      <td>{t("Tue")}</td>
                      <td>09-00-17:30</td>
                    </tr>
                    <tr>
                      <td>{t("Wen")}</td>
                      <td>09-00-17:30</td>
                    </tr>
                    <tr>
                      <td>{t("Thu")}</td>
                      <td>09-00-17:30</td>
                    </tr>
                    <tr>
                      <td>{t("Fri")}</td>
                      <td>09-00-17:30</td>
                    </tr>
                    <tr>
                      <td>{t("Sat")}</td>
                      <td>{t("Closed")}</td>
                    </tr>
                    <tr>
                      <td>{t("Sun")}</td>
                      <td>{t("Closed")}</td>
                    </tr>
                  </tbody>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
